jQuery(function() {

	/**
	 *  Preload
	 */

	jQuery(window).on('load', function() {
		jQuery('.preload').removeClass('preload');
	});


	/**
	 * External link
	 */

	var host = window.location.hostname;
    var domain = host.replace("www.", "");
    jQuery('a[href^="http"]')
        .not('a[href*="' + domain + '"]')
        .attr("target", "_blank");


    /**
     * 	Gallery
     */

    var carousel = jQuery('#Glide').glide({
		type: 'carousel',
		mode: 'vertical',
		startAt: 1,
		autoplay: false
	});
    slider_api = carousel.data('glide_api');


	/**
	 * Responsive
	 */

	browserWidth = jQuery(window).innerWidth();
	browserHeight = jQuery(window).innerHeight();

	jQuery(window).on('resize', function() {
		browserWidth = jQuery(window).innerWidth();
		browserHeight = jQuery(window).innerHeight();

		if ( browserWidth <= 900 ) {
			// Do something
		} else {
			// Do something
		}
	});


	/*
		Hamburger Nav
	 */

	jQuery('.m-hamburger').on('click', function() {

		jQuery(this).toggleClass('is-open');

		jQuery('.l-nav').toggleClass('is-open');

	});


	/*
		Google Maps
	 */

	 jQuery('#triggerMap').on('click', function(event) {
	 	event.preventDefault();

		if ( jQuery('.m-map').length == 1 ) {
			jQuery('.page-template-template-contact .l-content').show();
			initialize();
		}

	 });


	 /*
	 	Trigger own lightbox
	  */

	// Open lightbox on slider
	jQuery(document).on('click', '#sliderTrigger', function(event) {

		jQuery('#Glide')
			.clone().attr('id', 'FullscreenGlide')
			.addClass('is-fullscreen')
			.appendTo( jQuery('.l-fullscreen') );
			//.addClass('is-fullscreen');

		// Add trigger to fullscreen gallery
		jQuery('.m-image__button--next').data('glide-trigger', '#Glide #FullscreenGlide');

		// Add trigger to fullscreen gallery
		jQuery('.m-image__button--prev').data('glide-trigger', '#Glide #FullscreenGlide');

		// Display fullscreen
		jQuery('.l-fullscreen').show();

		// Remove cloned items - glidejs bug
		jQuery('.l-fullscreen').find( jQuery('.glide__slide.clone') ).remove();

		// Pause small slider
		slider_api.pause();

		// Init fullscreen slider
		var fullscreenSlider = jQuery('#FullscreenGlide').glide({
			type: 'carousel',
			mode: 'vertical',
			startAt: slider_api.current(),
			autoplay: false
		});

		// Sync slider
		fullscreenSlider.on("swipeEnd.glide", function(event, data) {
		    slider_api.jump( '=' + data.index );
		});

	});

	// Open lightbox on single image
	jQuery(document).on('click', '#schemaTrigger', function(event) {

		jQuerythat = jQuery(this).parent('.m-image__scheme')

		jQuerythat.addClass('is-fullscreen');

		jQuery('.l-fullscreen').append( jQuerythat ).show();


	});

	// Close lightbox
	jQuery(document).on('click', '#closeTrigger', function(event) {

		// Remove fullscreen class
		var jQueryitem = jQuery('.l-fullscreen').find('.is-fullscreen').removeClass('is-fullscreen');
		jQuery('.l-fullscreen').hide();

		// If is slider
		if ( jQueryitem.hasClass('m-image__slider') ) {

			//jQuery('.m-image').prepend( jQueryitem );
			jQuery('.l-fullscreen > .m-image__slider').remove();

			//slider_api.refresh();
			slider_api.play();

		// Else is schema (single) image
		} else {

			jQuery('.m-image').append( jQueryitem );

		}

	});


	// Calculate DD Coorinates to DMS Coordinates
	if(!jQuery('span.lng').is(':empty') && !jQuery('span.lng').is(':empty')) {
		var dmsCoords = ddToDms(jQuery('span.lat').html(), jQuery('span.lng').html());
		if (dmsCoords) {
			jQuery('span.coordinates').html(dmsCoords);
		}

		jQuery('span.lng').hide();
		jQuery('span.lat').hide();
	}

});


/*
	Function for Google Maps
 */

function initialize() {
	var pos_x = jQuery('.m-map').attr('data-lat');
	var pos_y = jQuery('.m-map').attr('data-lng');

	// Set position
	var custom_map = new google.maps.LatLng(pos_x,pos_y);

	var mapOptions = {
		mapTypeId: google.maps.MapTypeId.ROADMAP,
		//disableDefaultUI: true,
		zoom: 15,
		center: custom_map,
		scrollwheel: false,
		//navigationControl: true,
		mapTypeControl: true,
		//scaleControl: false,
		draggable: true,
		mapId: "DEFAULT_MAP_ID"
	};

	var map = new google.maps.Map(document.getElementById('map'), mapOptions);

	// Change map to greyscale
   	//var mapstyle = [{ "stylers": [{ "saturation": -100}, {"lightness": 35 }] }];
	//map.setOptions({ styles: mapstyle });

	//var pinImage =  new google.maps.MarkerImage( template_url + "/img/map.png" );

	// Marker position
	var marker = new google.maps.marker.AdvancedMarkerElement({
		position: custom_map,
		map: map
		//icon: pinImage,
		//icon: '/wp-content/themes/gc/img/map.png',
		//url: jQuery('#feature_map').attr('data-link'),
	});

}


// This function returns the coordinate
// conversion string in DD to DMS.
// http://en.marnoto.com/2014/04/converter-coordenadas-gps.html
function ddToDms(lat, lng) {

   var lat = lat;
   var lng = lng;
   var latResult, lngResult, dmsResult;

   lat = parseFloat(lat);
   lng = parseFloat(lng);

   latResult = getDms(lat);

   // Call to getDms(lat) function for the coordinates of Latitude in DMS.
   // The result is stored in latResult variable.
   latResult += (lat >= 0)? ' N' : ' S';

   lngResult = getDms(lng);

   // Call to getDms(lng) function for the coordinates of Longitude in DMS.
   // The result is stored in lngResult variable.
   lngResult += (lng >= 0)? ' O' : ' W';

   // Joining both variables and separate them with a space.
   dmsResult = latResult + ' | ' + lngResult;

   // Return the resultant string
   return dmsResult;
}

function getDms(val) {

  var valDeg, valMin, valSec, result;

  val = Math.abs(val);

  valDeg = Math.floor(val);
  result = valDeg + "º";

  valMin = Math.floor((val - valDeg) * 60);
  result += valMin + "'";

  valSec = Math.round((val - valDeg - valMin / 60) * 3600 * 1000) / 1000;
  result += valSec + '"';

  return result;
}